import { Injectable, InjectionToken, Inject } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
export const OKTA_AUTH_CLIENT = new InjectionToken<any>('OKTA_AUTH_CLIENT');


@Injectable({
  providedIn: 'root'
})
export class OktaService {

  constructor(@Inject(OKTA_AUTH_CLIENT) private oktaAuthClient: OktaAuth) {}

  isUserLoggedIn(): Promise<boolean> {
    return this.oktaAuthClient.session.exists();
  }

  signIn(credentials) {
    return this.oktaAuthClient.signIn(credentials);
  }

  setCookieAndRedirect(sessionToken: string, redirectUrl?: string) {
    return this.oktaAuthClient.session.setCookieAndRedirect(sessionToken, redirectUrl);
  }

  sessionExists() {
    return this.oktaAuthClient.session.exists();
  }

  getTokens(scopes: string[]) {
    return this.oktaAuthClient.token.getWithoutPrompt({ scopes });
  }

  async logout(): Promise<void> {
    if (await this.isUserLoggedIn()) {
      await this.oktaAuthClient.revokeAccessToken();
      await this.oktaAuthClient.closeSession();
    }
  }
}

